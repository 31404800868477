.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}





@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.background{
  background: linear-gradient(160deg, #210535 36.42%, #102542 46.27%, #142043 50.71%, #1C1645 60.57%);
}

.sky-gradient {
  background: linear-gradient(160deg, #210535 36.42%, #102542 46.27%, #142043 50.71%, #1C1645 60.57%);
}


/* FLYING FOX */
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.floating-fox {
  animation: bounce 2s ease-in-out infinite;
}

/* STANDING FOX */
@keyframes tiltZoom {
  0% {
    transform: rotate(0deg) scale(1);
  }
  25% {
    transform: rotate(-5deg) scale(1.1);
  }
  50% {
    transform: rotate(0deg) scale(1);
  }
  75% {
    transform: rotate(5deg) scale(1.1);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}

.standing-fox {
  animation: tiltZoom 4s ease-in-out infinite;
}



/* STAR ANIMATION */
@keyframes twinkle {
  0%, 100% { opacity: 0.5; }
  50% { opacity: 1; }
}

.animate-twinkle {
  animation: twinkle 1.5s infinite alternate;
}

.shooting-star {
  position: absolute;
  width: 3px;
  height: 100px;
  background: linear-gradient(white, rgba(255, 255, 255, 0));
  animation: shooting-star 1s linear infinite;
  transform: rotate(45deg);
}

.galaxy-path { 
  position: absolute; 
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%; 
  background-image: url('./assets/main-galaxy-path.svg'); 
  background-size: contain;
  background-repeat: repeat-y; 
  z-index: 1; 
}

.cover {
  background-size: cover;
}
.stars-bg {
  position: relative; 
  z-index: -1; 
  color: white; 
}

@keyframes shooting-star {
  0% {
    transform: translateY(0) rotate(45deg);
    opacity: 1;
  }
  100% {
    transform: translateY(200px) rotate(45deg);
    opacity: 0;
  }

}

